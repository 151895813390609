import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import hero from '../img/hero.png';
import work from '../img/menu_work.png';
import mojn from '../img/menu_me.png';
import gif from '../img/per.jpg';
import medable from '../img/medable.jpeg';
import omhu from '../img/omhu.png';
import jobindex from '../img/jobindex-2.png';
import Popup from '../components/popup'; // Import the Popup component

export default function Home() {
    const [showPopup,
        setShowPopup] = useState(false);
    const [isAuthenticated,
        setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handleLinkClick = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            setShowPopup(true);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handlePasswordSubmit = (password) => {
        if (password === '1Q84') {
            setIsAuthenticated(true);
            sessionStorage.setItem('isAuthenticated', 'true');
            setShowPopup(false);
            navigate('/Medable'); // Redirect to /Medable
        } else {
            alert('Incorrect password');
        }
    };

    useEffect(() => {
        const authStatus = sessionStorage.getItem('isAuthenticated');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <div className="home">
            {showPopup && (<Popup onClose={handleClosePopup} onPasswordSubmit={handlePasswordSubmit}/>)}
            <section className="red-bg">
                <div className="middle mt-3">
                    <img src={gif} className="img-fluid" alt="gif"/>
                    <div className="services mt-3">
                        <div className="item-25 services">
                            <p className="case-intro">disciplines</p>
                            <ul className="small">
                                <li>Product Strategy</li>
                                <li>Service Design</li>
                                <li>Digital Experience</li>
                                <li>Concept</li>
                                <li>Prototyping</li>
                                <li>UX/UI</li>
                            </ul>
                        </div>
                        <div className="item-25 contact">
                            <p className="case-intro">Currently at</p>
                            <ul className="small">
                                <li className="paternity">Paternity Leave 🐣</li>
                                <li className="strike">Medable</li>
                                <li className="strike">Omhu</li>
                                <li className="strike">Jobindex</li>
                                <li className="strike">GoMore</li>
                            </ul>
                        </div>
                        <div className="item-50 blob">
                            <p className="case-intro">blob</p>
                            <p className="small">Product Design Lead from Copenhagen, Denmark, currently on
                                paternity leave. Prior to this, I was involved in building a platform for
                                decentralized clinical trials at Medable, a Silicon Valley-based company
                                renowned as a leading technology provider for patient-centric clinical trials. I
                                span across various disciplines, including Design Direction, Product Design,
                                Digital Experience, Strategy, and other related fields.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <hr id="work" className='mt-5'/>

            <div className="container-projects">
                <div className="container-projects-item_left-20 mt-4">
                    <div className='sticky '>
                        <img src={work} className="mini" alt=""/>
                        <div className='left-stick ml-2'>
                            <div className="menu-title">Work</div>
                            <p className="xs">
                                <b>Selected casestudies</b>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-projects-item_right-80">
                    <div class="">
                        <Link to="/Medable" className='cursor' onClick={handleLinkClick}>
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>MEDABLE</h3>
                                    <p className="small">Decentralising Clinical trials</p>
                                </div>
                                <div className="container-projects-item_right-80">
                                    <img src={medable} className="img-fluid" alt=""/>
                                    <p className='xs'>
                                        At Medable, a pioneer in decentralized clinical trials, I lead product design in
                                        our Copenhagen office, focusing on participant-facing platforms.
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Omhu">
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>OMHU</h3>
                                    <p className="small">AI-infused dermatology
                                    </p>
                                </div>
                                <div className="container-projects-item_right-80">
                                    <img src={omhu} className="img-fluid" alt=""/>
                                    <p className='xs'>
                                        Gain insight into how we worked with rapid iterations of ideas and concepts at
                                        the Leo Innovation Lab spinout, Omhu. Ideating and sketching together can often
                                        be a challenge. See how we dealt with this and how fast prototyping can be an
                                        effective tool for quickly validating ideas and sparking inspiration.</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Jobindex">
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>JOBINDEX</h3>
                                    <p className="small">Optimizing jobsearch
                                    </p>
                                </div>
                                <div className="container-projects-item_right-80">
                                    <img src={jobindex} className="img-fluid" alt=""/>
                                    <p className='xs'>
                                        Keeping track of jobs is a key use case scenario when people are looking for new
                                        opportunities. How can we meet this user need on Jobindex instead of users using
                                        external services</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <hr id="mojn"/>

            <div className="container-projects mt-4">
                <div className="container-projects-item_left-20 mt-4">
                    <div className='sticky '>
                        <img src={mojn} className="mini " alt=""/>
                        <div className='left-stick ml-2'>
                            <div className="menu-title">Moin</div>
                            <p className='xs'>
                                <b>[ˈmʌjn]</b>
                            </p>
                            <p className="xs ">Southern Jutlandish for Hello... or Goodbye, whatever you choose.</p>
                        </div>
                    </div>
                </div>
                <div className="container-projects-item_right-60 about">
                    {/* <div className='per mb-3 ' >
            <img src={per} className="img-fluid" alt=""/>
            <p className='xs m-2'><b>My bird Müsli and I</b> hanging out.. keeping it real you know..</p>
          </div>*/}
                    <h2 className="h2-responsive">About</h2>

                    <p className="small about-1 mt-2">
                        As a design lead, my role revolves around unveiling hidden pain points and
                        uncovering fresh market opportunities for companies. With hands-on expertise in
                        strategy, coding, and prototyping, alongside a stash of behavioral science books
                        discreetly tucked under my bed, I bring a unique skill set to the table for
                        building and promptly validating innovative concepts.
                        <br/><br/>
                        My journey began as a professionally trained musician and composer, shaping my
                        approach to creativity and innovation. This background lends a distinctive
                        perspective to my work, blending artistic creativity seamlessly with
                        technological innovation. I Believe we should embrace the unpredictability of
                        the creative process and acknowledge that the fundamental journey is inherently
                        unpredictable and something we should celebrate. However, to break down complex
                        problems into manageable parts, a toolbox is essential, and Design Thinking
                        frameworks are often an effective way to guide this process.
                        <br/><br/>
                        I seek roles where I can build strong product visions and tackle real problems,
                        transforming innovative ideas into bold concepts, whether as a leader or through
                        hands-on design.
                    </p>
                    <h2 className="h2-responsive">Experience</h2>
                    <div className="about-1 ">
                        <div className='item-45'>
                            <h3>Design Lead | Medable</h3>
                            <p className="subcaption mb-2">2022 - 2024</p>
                            <p className="small margin-0">Building a platform for decentralised clinical
                                trials, making participation more accessible for everyone.</p>
                        </div>
                        <div className='item-45'>
                            <h3>Sr. UX Designer | Omhu</h3>
                            <p className="subcaption mb-2">2021 - 2022</p>
                            <p className="small margin-0">Designing an AI infused app to tackle the
                                challenges in the skin health system and mitigate the shortage of
                                dermatologists.</p>
                        </div>
                        <div className='item-45'>
                            <h3>UX Specialist | Jobindex</h3>
                            <p className="subcaption mb-2">2018 - 2021</p>
                            <p className="small margin-0">Building and implementing a Design system that
                                covered Jobindex, It-Jobbank and Stepstone.dk and conceptualizing new services
                                for for the massive platform.</p>
                        </div>
                        <div className='item-45'>
                            <h3>Digital Designer | GoMore</h3>
                            <p className="subcaption mb-2">2015 - 2017</p>
                            <p className="small margin-0">GoMore was the first successful peer-to-peer
                                ridesharing platform created out of Denmark. I was part of the team from
                                2015-2017, where I developed and built the brand together with a bunch of highly
                                skilled people.
                            </p>
                        </div>
                    </div>
                    <h2 className="h2-responsive">Education</h2>
                    <div className="mb-200 about-1">
                        <div className='item-45'>
                            <h3>IT–University of Copenhagen
                            </h3>
                            <p className="subcaption mb-2">2015 - 2017</p>
                            <p className="small margin-0">Cand IT, Digital Design & Communication</p>
                        </div>
                        <div className='item-45'>
                            <h3>Copenhagen school of design and technology
                            </h3>
                            <p className="subcaption mb-2">2013-2015</p>
                            <p className="small margin-0">AP graduate</p>
                        </div>
                        <div className='item-45'>
                            <h3>Rhythmic Music Conservatory
                            </h3>
                            <p className="subcaption mb-2">2005 - 2008</p>
                            <p className="small margin-0">Bachelor in Music & Arts</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-projects-item_left-30 ">
                <div className="white-box mt-5">
                    <img src={hero} className="cursor logo" alt=""/>
                    <h2 class="h2-responsive">Product Design Lead</h2>
                    <h3>perloekkegaard@gmail.com</h3>
                </div>
            </div>

        </div>
    );
}
