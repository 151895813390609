import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import medablelogo from '../img/medableLogo.png';
import overview from '../img/medable/overview.gif';
import oldtasks from '../img/medable/old-tasks.gif';
import newtasks from '../img/medable/new-tasks.gif';
import timeline from '../img/medable/timeline.png';
import visit from '../img/medable/visits1.gif';
import mobile from '../img/medable/mobilehand.png';

export default function Projects() {
    const navigate = useNavigate();
    const [projectHeroVisible,
        setProjectHeroVisible] = useState(true); // Assuming initially visible

    useEffect(() => {
        const handleScroll = () => {
            const projectHero = document.getElementById('project-hero');
            if (projectHero) {
                const projectHeroRect = projectHero.getBoundingClientRect();
                const isProjectHeroVisible = projectHeroRect.bottom > 0 && projectHeroRect.top < window.innerHeight;
                setProjectHeroVisible(isProjectHeroVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="home page">
            <button
                className={`arrow ${ !projectHeroVisible
                ? 'blue'
                : ''}`}
                onClick={() => navigate(-1)}>
                <svg
                    width="120pt"
                    height="120pt"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
                </svg>
            </button>
            <div className="top mb-6 bg-purple">
                <img
                    src={medablelogo}
                    className="medable-logo center border-radius"
                    alt="Medable logo"/>
                <div className="row" id="project-hero">
                    <p className="case-study">Case Study</p>
                    <h1>Optimizing the Clinical Trial User Experience</h1>
                    <div className="badges mb-1">
                        <span className="badge">Concept</span>
                        <span className="badge">Product Design</span>
                        <span className="badge">UX</span>
                        <span className="badge">UI</span>
                        <span className="badge">Prototyping</span>
                    </div>
                    <section className="content mt-2">
                        <div className="item-top">
                            <p className="case-intro">About Medable</p>
                            <p>At Medable, I led design initiatives in our Danish office, focusing on the
                                patient experience as part of a product triad. We ensured that patient
                                centricity was more than just a buzzword, prioritizing their needs and
                                perspectives. By integrating patient feedback, we managed to deliver
                                high-quality concepts that aligned with both user needs and business goals.
                            </p>
                        </div>
                        <div className="item-top">
                            <p className="case-intro">Case</p>
                            <p>This case study is about clinical trial participants lacking
                                information during the trial, leading to disengagement.
                                Bridging this gap is crucial for keeping participants engaged and ensuring trial
                                success.</p>
                        </div>
                        <div className="item-top">
                            <p className="case-intro">Position</p>
                            <p>
                                Design Lead
                                <br/>Sr. Product Designer
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="container max-800 mb-10">
                <h2 className="mb-1">Problem Space</h2>
                <p>One of the biggest challenges for clinical trial participants is the lack of
                    clear, accessible information — both in their daily tasks and in the bigger
                    picture. On a micro level, participants often feel uncertain about what’s
                    expected of them: What are their specific tasks? What phase are they in? How do
                    they contribute to the trial’s overall progress? They rarely receive updates. On
                    a macro level, they often feel disconnected from the trial’s broader goals and
                    outcomes, and don’t always see how their participation fits into the full scope.</p>

                <p>Bridging this information gap is crucial. It’s about keeping participants
                    engaged and ensuring they see the value of their involvement, which directly
                    impacts the trial’s success.</p>
            </div>
            <div className="container ">
                <div className="item w-100">
                    <img
                        src={overview}
                        className="img-fluid mt-4 border-radius"
                        alt="Overview of the different app screens"/>
                </div>
            </div>
            <div className="container max-800 mb-10">

                <h2 className="mb-1">Improving Trial overview
                </h2>
                <p>The participant app served as a task manager, assisting clinical trial
                    participants with completing tasks such as consent forms, screenings,
                    questionnaires etc. These tasks are pre-defined in the trial protocol before the
                    trial begins. At the trial's start, the home screen displayed all tasks that
                    participants needed to complete over the next three months, which could impose a
                    significant cognitive load. Additionally, the home screen lacked information
                    about previous tasks, preventing participants from reviewing tasks they had
                    already completed.</p>
                <p>
                    To ease cognitive load, we separated past and future tasks into subviews. The
                    active tasks remained on the home screen, while participants could still access
                    past/future tasks if needed.</p>
                <p>We also introduced task durations, as tasks could take anywhere from 1 minute
                    to over 30 minutes. The system would provide average completion times based on
                    the type of task — whether it was a questionnaire, a measurement, or another
                    activity. Over time, these estimates became more personalized, adapting through
                    AI models based on individual usage.</p>

            </div>

            <div className="container">
                <div className="item ">
                    <img
                        src={oldtasks}
                        className="img-fluid mt-4 50 border-radius"
                        alt="The way tasks was were structured before"/>
                    <p className="mini mb-2 mt-1">
                        <strong>Before: </strong>
                        All tasks on one screen
                    </p>
                </div>

                <div className="item ">

                    <img
                        src={newtasks}
                        className="img-fluid mt-4 50 border-radius"
                        alt="The new way tasks are structured in the app"/>
                    <p className="mini mb-2 mt-1">
                        <strong>After: </strong>
                        Past & future tasks split into subsections
                    </p>

                </div>
            </div>

            <div className="container max-800 mb-10">

                <h2 className="mb-1">Improving onboarding
                </h2>
                <p>Clinical trials consist of multiple phases, and it’s essential that
                    participants understand what’s expected at each stage. However, the app lacked
                    any guidance on what to expect, leaving participants uncertain and reliant on
                    other touchpoints throughout the trial. To address this, it was important for
                    the team to clearly differentiate between these phases and provide participants
                    with insight into the journey ahead. The illustrations below demonstrate how we
                    tackled this by delivering phase-specific information at the moment it became
                    relevant. This information was also made accessible in a separate resources
                    section.
                </p>

            </div>
            <div className="container mb-minus-40">
                <div className="item w-100">
                    <img
                        src={timeline}
                        className="img-fluid mt-4 border-radius"
                        alt="Trial phase overview"/>
                </div>
            </div>
            <div className="container ">
                <div className="item ">
                    <img
                        src={visit}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Animated gif that shows how the initial visit is shown to the user"/>

                </div>

                <div className="item ">

                    <img
                        src={mobile}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Phone in the hands of a user who has the Medable app open"/>

                </div>
            </div>
            <div className="container max-800 mb-10">
                <p>Upcoming site visits — such as the very first conversation with the Site or
                    getting blood work done,along with televisits, were not presented to
                    participants, despite the information being available. This missed a crucial
                    opportunity to keep them engaged and informed. By highlighting upcoming events,
                    we address this gap and ensure participants stay both informed and involved.

                </p>

            </div>

        </div>

    );
}