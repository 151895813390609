import {useNavigate} from 'react-router-dom';
import omhulogo from '../img/omhu/omhuapp2.png';
import omhuloop from '../img/omhu/omhu-loop.png';
import postit from '../img/omhu/postit.png';
import ideas from '../img/omhu/ideas.png';
import capture from '../img/omhu/capture.gif';
import origami from '../img/omhu/origami.png';
import React, {useState, useEffect} from 'react';

export default function Projects() {
    const navigate = useNavigate();
    const [projectHeroVisible,
        setProjectHeroVisible] = useState(true); // Assuming initially visible

    useEffect(() => {
        const handleScroll = () => {
            const projectHero = document.getElementById('project-hero');
            if (projectHero) {
                const projectHeroRect = projectHero.getBoundingClientRect();
                const isProjectHeroVisible = projectHeroRect.bottom > 0 && projectHeroRect.top < window.innerHeight;
                setProjectHeroVisible(isProjectHeroVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="home page">
            <div className="top mb-6 bg-green">
                <img src={omhulogo} className="omhu-logo center" alt="Omhu logo"/>
                <div className="row" id="project-hero">
                    <p className="case-study">Case Study</p>
                    <h1>Prototyping at Omhu</h1>
                    <div className="badges mb-1">
                        <span className="badge">Workshop facilitation</span>
                        <span className="badge">Prototyping</span>
                        <span className="badge">Product Design</span>
                    </div>
                    <section className="content mt-2">
                        <div className="item-top">
                            <p className="case-intro">About Omhu</p>
                            <p>We built Omhu Care as a cutting-edge digital and remote alternative to the
                                physical dermatological visit. In-house dermatologists diagnosed patients based
                                on questionnaires and photos retrieved in the Omhu Care mobile app. This data
                                trained our advanced ML models, so over time the dermatologists were assisted by
                                these models to make accurate diagnoses. Omhu users received a skin consultation
                                within 48 hours, a significant improvement compared to the 160+ days waiting
                                time through the health care system in Denmark.</p>
                        </div>
                        <div className="item-top">
                            <p className="case-intro">Case</p>
                            <p>
                                This workshop case study highlights how swift, collaborative ideation and
                                sketching effectively tested and validated concepts, engaging both introverts
                                and extroverts in a balanced and inclusive manner.</p>
                        </div>
                        <div className="item-top">
                            <p className="case-intro">Position</p>
                            <p>Sr. UX Designer</p>
                        </div>
                    </section>
                </div>
            </div>
            <button
                className={`arrow ${ !projectHeroVisible
                ? 'blue'
                : ''}`}
                onClick={() => navigate(-1)}>
                <svg
                    width="120pt"
                    height="120pt"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
                </svg>
            </button>
            <div className="container max-800 mb-10">
                <h2>Concept & Opportunity</h2>
                <p>We noticed that many users were sending in low-quality images, making it
                    difficult for our dermatologists to diagnose conditions accurately. Despite
                    improvements in smartphone cameras, this continued to be a problem. Our focus
                    turned to finding ways for users to capture better-quality images and understand
                    them more clearly. Our goal was to enhance image clarity, streamline the
                    diagnostic process, and give users a better understanding and quicker medical
                    care.</p>
            </div>
            <div className="container max-1200 workshop">
                <img src={omhuloop} className="img-fluid mt-4 mb-4" alt="Omhu loop"/>
            </div>
            <div className="container max-800 workshop">

                <h2>The Workshop</h2>
                <p>The workshop was designed with one goal in mind: quickly move from initial
                    user insights to a single, actionable prototype. Rather than generating a
                    multitude of ideas, we focused on developing and refining one key concept —
                    getting it from an initial idea to a functional prototype in just a few days.
                    The challenge was balancing speed with quality, ensuring that we could build
                    something meaningful in a compressed timeframe without losing sight of user
                    needs.</p>
                <p>Think of it like a creative bootcamp where the finish line isn’t a concept
                    but a prototype that’s ready to be tested and broken down for improvements. It’s
                    about taking insights from users and getting them into form — quickly, iterating
                    until we had something real and ready for the next step.</p>

                <h2>Phase 1: Insights & Problem Framing
                </h2>
                <p>Before the workshop the team had done initial research - this research
                    provided an unbiased perspective on user needs and challenges, ensuring that our
                    initial discussions were grounded in genuine user insights rather than
                    influenced by the group's preconceptions or biases. The goal was to frame our
                    challenges clearly, so we had specific problems to solve. We worked quickly —
                    synthesizing research findings and distilling them into sharp, actionable How
                    Might We statements. This created a shared understanding and gave us a direction
                    for ideation.

                </p>
                <h3 class="omhu-h3">Activities</h3>
                <ul>
                    <li>Each participant independently wrote down pressing issues for 5 minutes to
                        gather unbiased input.</li>
                    <li>This approach ensured that introverts and extroverts could contribute
                        equally without being influenced by dominant voices in the group.</li>
                    <li>All individual issues were posted on a whiteboard.</li>
                    <li>The team collectively discussed these issues and used affinity mapping to
                        categorize and prioritize them.</li>
                    <li>This process highlighted the most critical pain points from both
                        dermatologists' and patients' perspectives.</li>

                </ul>
            </div>
            <div className="container max-1200 workshop">

                <img src={postit} className="img-fluid py-4" alt="Post-it notes"/>
            </div>
            <div className="container max-800 workshop  mb-6">
                <div class="mb-4 bg-white p-4">

                    <h3 class="omhu-h3">Problem statements</h3>
                    <ul>
                        <li>
                            <strong>Problem Statement:
                            </strong>
                            The photos are way too blurry to get anything useful out of. (Dermatologist)</li>
                        <ul>
                            <li>
                                <strong>HMW:
                                </strong>
                                How might we decrease the amount of blurriness in the photo before submitting a
                                photo?</li>
                        </ul>
                        <li>
                            <strong>Problem Statement:
                            </strong>
                            Time & place when capturing a photo is inconvenient (User)</li>
                        <ul>
                            <li>
                                <strong>HMW:
                                </strong>
                                How might we minimize the discomfort of the physical environment while taking a
                                photo?</li>
                        </ul>
                        <li>
                            <strong>Problem Statement:
                            </strong>
                            The place on the body where the condition is located is difficult to reach
                            (User)</li>
                        <ul>
                            <li>
                                <strong>HMW:
                                </strong>
                                How might we make it easy to capture photos all over the body?</li>
                        </ul>
                    </ul>
                    <p>These most agreed upon pain points made into actionable steps were driving
                        our ideation and prototyping phases effectively.</p>
                </div>
            </div>
            <div className="container max-800 mb-4">
                <h2>Phase 2: Rapid Ideation
                </h2>
                <p>In this phase, the team brainstormed possible solutions to the problem.
                    Unlike broader ideation sessions, we remained very focused on generating ideas
                    directly tied to the problem statement which kept kept us aligned.

                </p>
                <p>We asked all participants to sketch low fidelity concepts that solved one or
                    more of the problems we had come up with. Each concept conveyed a specific
                    moment in the interaction - before, during or after the image capture.</p>
                <p>The team was given 5 minutes to make the sketch, and subsequently hand over
                    the sketch to another one in the group. This should help participants not get
                    tunnel vision about their own ideas and instead keep their minds open about
                    different directions. We wanted to facilitate a space that continuously ignited
                    new inspiration within the group members and freed them from already established
                    ideas and assumptions.</p>
                <p className="mb-6">The sketching session left us with about 30 different design concepts, which
                    we afterward grouped and boiled down to 10 concepts that seemed feasible and
                    valuable. We then mapped these concepts into an impact-effort matrix, helping us
                    prioritize which ideas offered the most value relative to their implementation
                    complexity.</p>

                <img src={ideas} className="img-fluid py-4 mb-4 w-50" alt="Ideas"/>
            </div>
            <div className="container max-800 mb-10 pt-5">
                <h2 className="mb-0">Phase 3: Iterative Prototyping
                </h2>
                <p>We selected a few sketches to be the foundation for the interactive
                    prototype. This prototype was an investigation into whether you can guide the
                    user to hold the phone still by having a small square that moves when the user
                    moves the phone (on the right).</p>

            </div>
            <div className="container max-1200 pt-5">

                <div className="capture mb-3">
                    <img src={capture} className="omhu-vid mb-3" alt="Capture prototype"/>
                </div>
            </div>

            <div className="container max-800 mb-10 pt-5">

                <div class="mb-3">
                    <h3 class="omhu-h3">Choosing the right prototyping tool</h3>

                    <p>I needed to have access to the accelerometer in the mobile phone and be able
                        to take and save a picture, which is not possible using Figma, Protopie, etc.
                        Therefore, the choice fell on Origami, as this would give me these
                        possibilities.</p>
                    <p>This software is completely different from other prototyping software like
                        Principle or Protopie as it uses visual programming where you "write" your code
                        using boxes and lines.</p>
                </div>
            </div>
            <div className="container max-1200 pt-5">

                <img src={origami} className="img-fluid mb-5" alt="Origami prototype"/>
            </div>
            <div className="container max-800 ">

                <div class="mb-4 bg-white p-4">
                    <h3 class="omhu-h3">Phase 4: Testing & Iteration
                    </h3>

                    <p>
                        We conducted one round of user testing towards the end of the workshop, testing
                        the prototype with four individuals who were not involved in the project to
                        ensure unbiased feedback. This test provided crucial insights into how the
                        prototype functioned in a real-world context. Users misunderstood a key feature
                        — specifically, they thought the square indicated focus rather than movement —
                        revealing several design flaws that had been overlooked during the rapid build
                        process. This feedback was invaluable, highlighting areas that needed further
                        refinement to better align the prototype with user expectations.</p>
                </div>
            </div>
            <div className="container max-800 ">
                <h2>Outcomes and Reflections</h2>
                <p>By the end of the workshop, we had developed a functional prototype. However,
                    it was clear that the prototype was just a starting point rather than a final
                    solution. The feedback we received during testing highlighted several
                    fundamental design flaws that we hadn't anticipated in the earlier stages. These
                    included issues related to usability, user interaction, and even the core
                    functionality of the product.</p>
                <p>
                    While this rapid approach had its benefits — namely, producing something
                    tangible in a short period — it also left us with more unresolved questions than
                    answers. Many of the more intricate aspects of the design were left
                    underdeveloped, and the next steps would require much more detailed refinement.</p>
                <p >In the end, this experience reinforced an important lesson: rapid iteration
                    is valuable, but it should be balanced with time for more thoughtful
                    exploration. The single-prototype approach helped us dive deep, but in
                    hindsight, incorporating more time for divergence or multiple iterations might
                    have resulted in a more refined prototype.</p>
                            <h2>Credits:</h2>
                <p>
                    <strong>My role:
                    </strong>
                    Fascilitation, prototyping, tests<br/>
                    <strong>Workshop team:
                    </strong>
                    Alice Moynihan, Tørk Egebjerg, Malena Jespersen, Mads Heinrichsen, Johan
                    Sundgren, Matt Avis<br/>
                    <strong>Year:
                    </strong>
                    2022</p>

            </div>
        </div>
    );
}
